// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.portal.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppEnvironment } from '../app/app-environment';
import packageJson from '../../package.json'

const clientId = 'df6dc385-c2ad-4b45-8fe3-1bfb6369b483';
const policyId = 'B2C_1_SUSI';
const authApiBaseUrl = 'https://api-auth-bsi-qa-002.internal.bsiconnect.app/api';

export const environment: AppEnvironment = {
  production: false,
  adminApiBaseUrl: 'https://api-admin-bsi-qa-002.internal.bsiconnect.app/api',
  authApiBaseUrl: authApiBaseUrl,
  documentApiBaseUrl: 'https://api-document-bsi-qa-002.internal.bsiconnect.app/api',
  storageApiBaseUrl: 'https://api-storage-bsi-qa-002.internal.bsiconnect.app/api',
  clientId: clientId,
  authority: `https://bsiconnectauthdev.b2clogin.com/bsiconnectauthdev.onmicrosoft.com/${policyId}`,
  knownAuthorities: ['https://bsiconnectauthdev.b2clogin.com'],
  redirectUrl: 'https://portal-qa-002.internal.bsiconnect.app',
  authApiProtectedResource: `${authApiBaseUrl}/auth/token-from-credentials`,
  authApiScope: 'https://bsiconnectauthdev.onmicrosoft.com/auth-api/all',
  buildId: '171577',
  buildNumber: '20250120.11',
  pipeline: 'artemis-core-internal',
  branch: 'refs/tags/R023-codefreeze',
  stage: 'internal',
  repository: 'artemis-core',
  commit: '172af9b8b56e9a96ed4d2ead659f1410d390b01d',
  environmentId: '110',
  environmentName: 'bsi-connect-platform-internal-qa',
  version: packageJson.version,
  workspaceUrl: 'https://qa-002.internal.bsiconnect.app/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
